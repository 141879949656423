$(window).ready(function(){

	function init(){
		$('#menubutton').click(function(e){
			$('.overlay').addClass('open');
			$('#wrapper').addClass('overlay-open');
		});

		$('.overlay-close').click(function(e){
			$('.overlay').removeClass('open');
			$('#wrapper').removeClass('overlay-open');
		});

		transition();

		clamp();

		$(window).resize(function(){
			clamp();
		});

		orderProduct();

		homeslider();

		$('#grid').length && masonry();

		imagePopup();
	}

	function orderProduct(){
		$('.productForm .size, .productForm .amount').val('');

		$('.productForm input, .productForm select').change(function(e){
			var form = $(this).closest('form');
			var button = form.find('button');
			var amount = form.find('.amount').val();
			var size = form.find('.size').val();

			if(amount !== '' && size !== '' && size !== null){
				button.removeClass('invisible');
			}else{
				button.addClass('invisible');
			}
		});
	}

	function masonry(){
		var grid = document.querySelector('.grid');

		var msnry = new Masonry( grid, {
		  itemSelector: '.grid-item',
		  columnWidth: '.grid-sizer',
		  percentPosition: true
		});

		imagesLoaded( grid ).on( 'progress', function() {
		  // layout Masonry after each image loads
		  msnry.layout();
		});
	}

	function imagePopup(){
		if ($('.grid').length){
			$('.grid').magnificPopup({
				delegate: '.grid-item a',
				type: 'image',
				tLoading: '',
				mainClass: 'mfp-img-mobile',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [1,1] // Will preload 0 - before current, and 1 after the current image
				},
				image: {
					tError: '<a href="%url%">De afbeelding</a>kon niet geladen worden.',
					titleSrc: function(item) {
						return item.el.attr('title');
					}
				}
			});
		}
	}

	function transition(){
		$(".animsition").animsition({
		    inClass: 'zoom-in-sm',
		    outClass: 'zoom-out-sm',
		    inDuration: 750,
		    outDuration: 750,
		    linkElement: 'h1[onclick],.close-cookies:not,a:not([href*="tel:"]):not([href*="#"]):not([href*="mailto:"]):not([target="_blank"])',
		    loading: false,
		    loadingParentElement: 'body',
		    loadingClass: 'animsition-loading',
		    loadingInner: '',
		    timeout: false,
		    timeoutCountdown: 5000,
		    onLoadEvent: true,
		    browser: [ 'animation-duration', '-webkit-animation-duration'],
		    unSupportCss: ["animation-duration", "-webkit-animation-duration", "-o-animation-duration"],
		    transition: function(url){ window.location.href = url; }
  		});
	}

	function clamp(){
		$('.clamp-1').each(function(index, element) {
		    $clamp(element, { clamp: 1, useNativeClamp: false });
		});

		$('.clamp-2').each(function(index, element) {
		    $clamp(element, { clamp: 2, useNativeClamp: false });
		});

		$('.clamp-3').each(function(index, element) {
		    $clamp(element, { clamp: 3, useNativeClamp: false });
		});

		$('.clamp-4').each(function(index, element) {
		    $clamp(element, { clamp: 3, useNativeClamp: false });
		});
	}

	function homeslider(){
		$('#homeslider').flexslider({
			animation: "fade",
			controlNav: false,
			directionNav: false,
			animationLoop: true,
			slideshow: true,
			touch: false,
			slideshowspeed: 4000
		});
	}

	init();
});

window.initMap = function(){
	window.locations = [];
	if($('#google-map').length){
		window.initMap = null;

		var json = JSON.parse($('#sellingpoints-data').html());

		var infowindow = new google.maps.InfoWindow();
		var geocoder = new google.maps.Geocoder();

		var icons = {
		  point: {
		    icon: '/assets/images/pin.png'
		  }
		};

		var map = new google.maps.Map(document.getElementById('google-map'), {
	      	zoom: 8,
	      	point: {lat: 50.849515, lng: 4.346261}
	    });

	    map.setCenter(new google.maps.LatLng(50.849515, 4.346261));

	    $('#submit').click(function() {
	      	geocodeAddress(geocoder, map, json);
	    });

	    placeMarkers(geocoder, map, json, icons);
	}
};

window.geocodeAddress = function(geocoder, map, json) {
  	var address = $('#address').val()+', Belgium';

  	geocoder.geocode({'address': address}, function(results, status) {
        if (status === 'OK') {
          	map.setZoom(14);
          	findClosestPin(results, map);
        } else {
          	alert('Geocode was not successful for the following reason: ' + status);
        }
  	});
};

window.findClosestPin = function(results, map){
 	var closest = 0;
 	var allplaces = [];

	$.each(window.locations, function(i, e){
		var distance = google.maps.geometry.spherical.computeDistanceBetween(e.pos,results[0].geometry.location);
		e.distance = distance;

		allplaces.push(e);

	    if(closest.distance > distance || closest === 0){
	    	closest = e;
	    }
	});

	map.setCenter(closest.pos);

	allplaces.sort(function(a, b) {
	    return parseFloat(a.distance) - parseFloat(b.distance);
	});

	allplaces.splice(3, allplaces.length - 3);

	$('#top-three').empty();
	$.each(allplaces, function(i, e){
		$('#top-three-title').removeClass('hidden');
		$('#top-three').append(e.content);
	});
}

window.placeMarkers = function(geocoder, map, json, icons) {
	for (var i = 0; i < json.points.length; i++) {
		var address = json.points[i].address + ', ' + json.points[i].city;
		var content = '<div class="marker"><strong>' + json.points[i].name + '</strong><p>' + json.points[i].address + ', ' + json.points[i].city + '</p><p><a href="mailto:' + json.points[i].email + '">' + json.points[i].email + '</a></p><p><a href="tel:' + json.points[i].telephone + '">' + json.points[i].telephone + '</a></p></div>';
		setMarker(geocoder, map, json, icons, i, content, address, json.points[i]);
  	}
};

window.setMarker = function(geocoder, map, json, icons, i, content, address, point) {
	var infowindow = new google.maps.InfoWindow();
	if(point['lat'] == "" || point['lng'] == ""){
		geocoder.geocode({'address': address}, function(results, status) {
		    if (status === 'OK') {
		     	var marker = new google.maps.Marker({
		      		map: map,
		      		position: results[0].geometry.location,
		      		icon: icons.point.icon
		     	});

		     	window.locations.push({'pos' : results[0].geometry.location, 'content':content});

		     	google.maps.event.addListener(marker, 'click', function() {
		     		infowindow.setContent(content);
			      	infowindow.open(map, this);
			    });

		     	point['lat'] = results[0].geometry.location.lat();
		     	point['lng'] = results[0].geometry.location.lng();

			    $.ajax({
				  	url: '/ajax',
				  	data : {'pointData': point},
				  	type: 'POST'
				}).done(function(e) {
				  	console.log(e, 'ajax done');
				});
		    }else{
		      	console.log('Geocode was not successful for the following reason: ' + status);
		    }
		});
	}else{
		var marker = new google.maps.Marker({
			map: map,
			position: new google.maps.LatLng(point['lat'], point['lng']),
			icon: icons.point.icon
		});

		window.locations.push({'pos' : new google.maps.LatLng(point['lat'], point['lng']), 'content':content});

		google.maps.event.addListener(marker, 'click', function() {
			infowindow.setContent(content);
		  	infowindow.open(map, this);
		});
	}
};
